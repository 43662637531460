import { log } from "shared/util/log"

export interface AnalyticsService {
    /**
     * Initializes the analytics service. Must be called before any other method.
     *
     * @param trackLoginEvent if true, a login event is tracked.
     */
    initialize(trackLoginEvent: boolean): Promise<void>

    /**
     * Tracks a menu navigation event.
     *
     * @param rootNode The root node of the path. Example: `Setup`
     * @param path The path of the menu item. Example: `Setup / Campaign Management / Channels`
     */
    trackMenuNavigation(rootNode: string, path: string): Promise<void>
}

export const noopAnalyticsService = new (class implements AnalyticsService {
    async initialize(trackLoginEvent: boolean) {
        log.info(`noopAnalyticsService.initialize(${trackLoginEvent})`)
    }

    async trackMenuNavigation(rootNode: string, path: string) {
        log.info(`noopAnalyticsService.trackMenuNavigation(${rootNode}, ${path})`)
    }
})()

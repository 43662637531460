import { get } from "shared/service"
import { AppContextDTO } from "generated/models"
import { DataDefinitions } from "domain/reporting/ReportingConfigurationContext"
import {
    convertDataGroups,
    convertDimensions,
    convertInfoTexts,
    convertMetrics,
    convertMetricsFrontendGroups,
} from "domain/ColumnConfigurator/adapter/adapter"
import UrlService from "shared/service/url.service"
import {
    DataGroupsResponseDTO,
    DimensionsResponseDTO,
    FrontendGroupsResponseDTO,
    InfoTextsResponseDTO,
    MetricsResponseDTO,
} from "generated/models"

export const ReportingService = {
    /**
     * Loads data definitions from the backend asynchronously
     *
     * @param appContext
     */
    loadDataDefinitions: async (appContext: AppContextDTO): Promise<DataDefinitions> => {
        const [dataGroups, dimensions, metrics, frontendGroups, infoTexts] = await Promise.all([
            loadDataGroups(appContext),
            loadDimensions(appContext),
            loadMetrics(appContext),
            loadFrontendGroups(appContext),
            loadInfoTexts(appContext),
        ])

        return {
            dimensions: convertDimensions(dimensions),
            metrics: convertMetrics(metrics),
            dataGroups: convertDataGroups(dataGroups),
            metricsFrontendGroups: convertMetricsFrontendGroups(frontendGroups),
            infoTexts: convertInfoTexts(infoTexts),
        }
    },
}
const loadDataGroups = async (appContext: AppContextDTO): Promise<DataGroupsResponseDTO> => {
    return get(`${UrlService.getUiServiceApiUrl()}/reporting/loadDataGroups`, {
        params: getAdvertiserCampaignParams(appContext),
    })
}

const loadDimensions = async (appContext: AppContextDTO): Promise<DimensionsResponseDTO> => {
    return get(`${UrlService.getUiServiceApiUrl()}/reporting/loadDimensions`, {
        params: getAdvertiserCampaignParams(appContext),
    })
}

const loadMetrics = async (appContext: AppContextDTO): Promise<MetricsResponseDTO> => {
    return get(`${UrlService.getUiServiceApiUrl()}/reporting/loadMetrics`, {
        params: getAdvertiserCampaignParams(appContext),
    })
}
const loadFrontendGroups = async (appContext: AppContextDTO): Promise<FrontendGroupsResponseDTO> => {
    return get(`${UrlService.getUiServiceApiUrl()}/reporting/loadFrontendGroups`, {
        params: getAdvertiserCampaignParams(appContext),
    })
}
const loadInfoTexts = async (appContext: AppContextDTO): Promise<InfoTextsResponseDTO> => {
    return get(`${UrlService.getUiServiceApiUrl()}/reporting/loadInfoTexts`, {
        params: getAdvertiserCampaignParams(appContext),
    })
}

/**
 * Gets advertiser and optionally campaign params from the appContext
 *
 * @param appContext
 */
const getAdvertiserCampaignParams = (appContext: AppContextDTO) => {
    const params = { advertiser: appContext.advertiserId }

    if (appContext.campaignId) {
        return { ...params, campaign: appContext.campaignId }
    } else {
        return params
    }
}

import React from "react"
import { ConversionListColumnConfiguratorAdapter } from "domain/ConversionList/components/ConversionListColumnConfiguratorAdapter"
import { ConfigPanel } from "domain/ConversionList/components/ConfigPanel/ConfigPanel"
import { GridPanel } from "domain/ConversionList/components/GridPanel/GridPanel"

export const ConversionList = () => {
    return (
        <div>
            <ConversionListColumnConfiguratorAdapter>
                <ConfigPanel />
                <GridPanel />
            </ConversionListColumnConfiguratorAdapter>
        </div>
    )
}
